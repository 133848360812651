<template>
  <div id='billrecord'>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item class="myColor1">首页</el-breadcrumb-item>
      <el-breadcrumb-item class="myColor2">账单管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <!-- 搜索区域视图 -->
      <el-row :gutter="20">
        <el-col :span="4">
          <el-input v-model="queryFrom.id" placeholder="订单号" class="input-with-select">
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-input v-model="queryFrom.carNumber" placeholder="车牌号/客户姓名" class="input-with-select">
          </el-input>
        </el-col>
        <el-col :span="3">
          <el-select v-model="queryFrom.cityId" clearable placeholder="所在城市">
            <el-option :key="item.cityId" v-for="item in optionsCity" :label="item.cityName" :value="item.cityId"></el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="queryFrom.companyId" clearable placeholder="所属公司">
            <el-option :key="item.companyId" v-for="item in optionsCompany" :label="item.companyName" :value="item.companyId"></el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="queryFrom.state" clearable placeholder="付款状态">
            <el-option :key="item.value" v-for="item in optionsStatus" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="queryFrom.paidout" clearable placeholder="出账状态">
            <el-option :key="item.value" v-for="item in paidoutStatus" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="queryFrom.overdue" clearable placeholder="逾期状态">
            <el-option :key="item.value" v-for="item in overdueStatus" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row style="marginTop: 20px" :gutter="20">
        <el-col :span="3">
          <el-select v-model="queryFrom.type" clearable placeholder="类型">
            <el-option :key="item.value" v-for="item in optionsType" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-col>
        <el-col :span="8" class="billDatePicker">
          <el-date-picker @change="paymentDateChange" v-model="paymentDate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-col>
        <el-col :span="4">
          <el-button class="searchButton" type="primary" size="medium" @click="searchList()">查询</el-button>
        </el-col>
      </el-row>
    </el-card>
    <el-card>
      <div class="table_header">
        <el-button type="warning" v-no-more-click round size="mini" icon="el-icon-download" @click="DownBillExcel()">导出</el-button>
      </div>
      <!-- 表格数据 -->
      <el-table :data="tableData" style="width: 100%" border stripe :header-cell-style="{color:'#333',fontFamily:'MicrosoftYaHeiUI',fontSize:'14px',fontWeight:900,backgroundColor:'#eff3f8'}">
        <el-table-column type="index" label="序" width="50" align="center" fixed="left">
          <template slot-scope="scope">
            <span>{{(pagenum - 1) * size + scope.$index + 1}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="id" label="订单号" width="170" fixed="left">
        </el-table-column>
        <el-table-column prop="carNumber" label="车牌" width="100">
        </el-table-column>
        <el-table-column prop="contractNo" label="合同编号" width="170">
        </el-table-column>
        <el-table-column prop="periodsTotal" label="期数" align="center">
        </el-table-column>
        <el-table-column prop="customer" label="客户">
        </el-table-column>
        <el-table-column label="公司" width="120">
          <template slot-scope="scope">
            {{scope.row.cityName}}{{scope.row.companyName}}
          </template>
        </el-table-column>
        <el-table-column prop="amountFormat" label="扣款金额">
        </el-table-column>
        <el-table-column label="类型">
          <template slot-scope="scope">
            {{scope.row.type === 'plan' ? '正常月付' : '定制扣款'}}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="createDateStr" label="创建时间" width="100">
        </el-table-column>
        <el-table-column label="付款状态" align="center" width="110">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.state === 1" type="danger">未付</el-tag>
            <el-tag v-else-if="scope.row.state === 2">已付</el-tag>
            <el-tag v-else-if="scope.row.state === 5" type="success">已付款-部分</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="出账状态" align="center" width="120">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.paidout === 0" type="danger">未出账</el-tag>
            <el-tag v-else-if="scope.row.paidout === 1">已出账</el-tag>
            <el-tag v-else-if="scope.row.paidout === 2" type="info">合同已结束</el-tag>
            <div v-else-if="scope.row.paidout === null">--</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" :width="180" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="success" icon="el-icon-tickets" @click="readRecord(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagenum" :page-sizes="[1, 5, 10, 20]" :page-size="size"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </el-card>
    <!-- 查看账单信息 -->
    <el-dialog class="customBillDialog" width="40%" title="账单信息" :visible.sync="PreviewVisible" append-to-body @close="PerviewhiddlenDialog">
      <el-descriptions :colon="false" :labelStyle="{width: '100px'}" style="font-size: 16px;" :column="1" size="medium">
        <el-descriptions-item label="订单号" :contentStyle="{'color': 'black'}">
          {{ billPreviewData.id }}
        </el-descriptions-item>
        <el-descriptions-item label="合同编号" :contentStyle="{'color': 'black'}">
          <el-link v-if="billPreviewData.contractNo" type="primary" :underline="false" @click="SkipLese(billPreviewData.contractId)">{{ billPreviewData.contractNo }}</el-link>
          <span v-else>--</span>
        </el-descriptions-item>
        <template v-if="billPreviewData.enablePart === null">
          <el-descriptions-item label="支付时间" :contentStyle="{'color': 'black'}">
            {{ billPreviewData.paymentDate }}
          </el-descriptions-item>
          <el-descriptions-item label="支付方式" :contentStyle="{'color': 'black'}">
            {{ billPreviewData.paymentMethod }}
          </el-descriptions-item>
          <el-descriptions-item label="交易单号" :contentStyle="{'color': 'black'}">
            {{ billPreviewData.transactionNumber }}
          </el-descriptions-item>
          <el-descriptions-item label="商户号" :contentStyle="{'color': 'black'}">
            {{ billPreviewData.merchantId }}
          </el-descriptions-item>
        </template>        
        <el-descriptions-item :labelStyle="{width: '0px'}">
          <div style="width: 100%; height: 1px; background-color: #dcdfe6; position: relative; left: -10px;"></div>
        </el-descriptions-item>
        <el-descriptions-item label="操作员" :contentStyle="{'color': 'black'}">
          {{ billPreviewData.createUserName }}
        </el-descriptions-item>
        <el-descriptions-item label="创建时间" :contentStyle="{'color': 'black'}">
          {{ billPreviewData.createDate }}
        </el-descriptions-item>
        <el-descriptions-item v-if="billPreviewData.enablePart === 1" :labelStyle="{width: '0px'}">
          <div style="width: 100%; height: 1px; background-color: #dcdfe6; position: relative; left: -10px;"></div>
        </el-descriptions-item>
      </el-descriptions>
      <template v-if="billPaidList.length > 0">
        <div class="billPaidListTitle">已还款记录</div>
        <el-steps direction="vertical" :space="120">
          <el-step icon="el-icon-success" status="process" v-for="item in billPaidList" :key="item.id" :title="'还款金额: ' + item.amount">
            <template slot="description">
              <div>
                <div>交易单号：{{ item.transactionNumber }}</div>
                <div>支付方式：{{ item.paymentMethod }}</div>
                <div>支付时间：{{ item.paymentDate }}</div>
                <div>商户号：{{ item.merchantId }}</div>
              </div>
            </template>
          </el-step>
        </el-steps>
      </template>
    </el-dialog>

  </div>
</template>
    
<script>
import { cityList, companyList } from "@/api/index.js";
import { getBillList, exportBillExcel, getBillPaidList } from "@/api/billRecord.js";

export default {
  name: "",
  data() {
    return {
      queryFrom: {
        id: "", // 订单号
        carNumber: "", // 车牌号
        cityId: "", // 所在城市
        companyId: "", // 所属公司
        state: "", // 付款状态
        type: "", //账单类型
        paidout: "", // 付款状态
        overdue: "", // 付款状态
        startDate: "", // 开始时间
        endDate: "", // 结束时间
      },
      paymentDate: "", //交易时间范围
      optionsCity: [], //城市列表
      optionsCompany: [], // 公司列表
      optionsStatus: [
        { value: 1, label: "未付" },
        { value: 2, label: "已付" },
        { value: 5, label: "已付款-部分" },
      ],
      optionsType: [
        { value: "plan", label: "正常月付" },
        { value: "bill", label: "定制扣款" },
      ],
      paidoutStatus: [
        { value: 0, label: "未出账" },
        { value: 1, label: "已出账" },
        { value: 2, label: "合同已结束" },
      ],
      overdueStatus: [
        { value: 0, label: "未逾期" },
        { value: 1, label: "已逾期" },
      ],
      tableData: [],
      pagenum: 1, // 页码
      size: 10, // 单页数据数
      total: 0, //数据总量

      PreviewVisible: false, //查看弹窗
      billPreviewData: {}, // 预览数据
      billPaidList: [], // 已付款账单列表
    };
  },
  watch: {
    // 监听城市选项是否为空
    "queryFrom.cityId"(newVal) {
      if (newVal == "") {
        this.optionsCompany = [];
        this.queryFrom.companyId = "";
      } else {
        // 获取公司列表
        this.getcompanyList();
        this.queryFrom.companyId = "";
      }
    },
  },
  created() {
    this.getcityList();
    this.getListData();
  },
  methods: {
    // 获取城市列表
    getcityList() {
      cityList().then((res) => {
        this.optionsCity = res.data.dataList;
        window.sessionStorage.setItem("cityList", JSON.stringify(this.optionsCity));
      });
    },
    // 获取城市列表~
    getcompanyList() {
      companyList(this.queryFrom.cityId).then((res) => {
        console.log(res);
        this.optionsCompany = res.data.dataList;
      });
    },
    paymentDateChange(date) {
      if (date && date.length > 0) {
        this.queryFrom.startDate = date[0];
        this.queryFrom.endDate = date[1];
      } else {
        this.queryFrom.startDate = "";
        this.queryFrom.endDate = "";
      }
    },
    searchList() {
      this.pagenum = 1;
      this.size = 10;
      this.getListData();
    },
    getListData() {
      getBillList(this.queryFrom, this.pagenum, this.size).then((res) => {
        console.log(res);
        const dataList = [];
        res.data.dataList.forEach((element) => {
          element.amountFormat = Number(element.amount).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
          element.createDateStr = this.$moment(element.createDate).format("YYYY-MM-DD HH:mm:ss");
          dataList.push(element);
        });
        this.tableData = dataList;
        this.total = res.data.total;
      });
    },
    // 监听pagesize改变的事件
    handleSizeChange(newSize) {
      this.size = newSize;
      this.pagenum = 1;
      this.getListData(); // 发起数据请求
    },
    // 监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.pagenum = newPage;
      this.getListData(); // 发起数据请求
    },
    readRecord(billInf) {
      billInf.createDate = this.$moment(billInf.createDate).format("YYYY-MM-DD HH:mm:ss");
      billInf.paymentDate = billInf.paymentDate ? this.$moment(billInf.paymentDate).format("YYYY-MM-DD HH:mm:ss") : "";
      this.billPreviewData = billInf;
      if (billInf.enablePart === null) {
        this.PreviewVisible = true;
        return false;
      }
      getBillPaidList(billInf.id).then((res) => {
        const billPaidList = res.data.map(res => {
          res.amount = res.amount.toString();
          res.paymentDate = res.paymentDate ? this.$moment(res.paymentDate).format("YYYY-MM-DD HH:mm:ss") : "";   
          return res;
        });  
        this.billPaidList = billPaidList;
        this.PreviewVisible = true;      
      })
    },
    PerviewhiddlenDialog() {
      this.billPreviewData = {};
      this.PreviewVisible = false;
      this.billPaidList = [];
    },
    // 下载excel表格
    DownBillExcel() {
      var _this = this;
      exportBillExcel(this.queryFrom)
        .then((res) => {
          console.log(res);
          // 1,创建blob对象
          const blob = new Blob([res], {
            type: "application/vnd.ms-excel;charset=utf-8",
          });
          const newTime = _this.$moment().format("YYYYMMDDkkmmss");
          const fileName = "billExcel_" + newTime; // 文件名

          // 2, 创建类文件对象, 导入blob数据源
          const url = window.URL.createObjectURL(blob);

          // 生成a标签存入body,利用herf属性下载
          const dom = document.createElement("a");
          dom.style.display = "none";
          dom.href = url;
          dom.setAttribute("download", fileName);
          document.body.appendChild(dom);
          dom.click();

          this.$message.success("正在下载...");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 查看合同详情
    SkipLese(id) {
      this.$router.push({
        name: "lease",
        params: {
          id, //租赁合同contractId
        },
      });
    },
  },
};
</script>
    
<style lang='less' scoped>
#billrecord {
  padding: 60px 20px 20px 20px;
}

.billDatePicker .el-range-editor .el-input__inner {
  width: 100%;
}
</style>