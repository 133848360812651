<template>
  <div id='customizedBill'>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item class="myColor1">首页</el-breadcrumb-item>
      <el-breadcrumb-item class="myColor2">定制账单</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <!-- 搜索区域视图 -->
      <el-row :gutter="20">
        <el-col :span="4">
          <el-input v-model="queryFrom.id" placeholder="订单号" class="input-with-select">
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-input v-model="queryFrom.carNumber" placeholder="车牌号/客户姓名" class="input-with-select">
          </el-input>
        </el-col>
        <el-col :span="3">
          <el-select v-model="queryFrom.cityId" clearable placeholder="所在城市">
            <el-option :key="item.cityId" v-for="item in optionsCity" :label="item.cityName" :value="item.cityId"></el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="queryFrom.companyId" clearable placeholder="所属公司">
            <el-option :key="item.companyId" v-for="item in optionsCompany" :label="item.companyName" :value="item.companyId"></el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="queryFrom.state" clearable placeholder="付款状态">
            <el-option :key="item.value" v-for="item in optionsStatus" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-col>
        <el-col :span="7">
          <el-button class="searchButton" type="primary" size="medium" @click="searchList()">查询</el-button>
        </el-col>
      </el-row>
      <el-row>
      </el-row>
    </el-card>
    <el-card>
      <div class="table_header">
        <el-button type="primary" round size="mini" icon="el-icon-plus" @click="addBill()">新增扣款</el-button>
      </div>

      <!-- 表格数据 -->
      <el-table :data="tableData" style="width: 100%" border stripe :header-cell-style="{color:'#333',fontFamily:'MicrosoftYaHeiUI',fontSize:'14px',fontWeight:900,backgroundColor:'#eff3f8'}">
        <el-table-column type="index" label="序" width="50" align="center">
          <template slot-scope="scope">
            <span>{{(pagenum - 1) * size + scope.$index + 1}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="id" label="订单号" width="170">
        </el-table-column>
        <el-table-column prop="carNumber" label="车牌" width="100">
        </el-table-column>
        <el-table-column prop="customer" label="客户">
        </el-table-column>
        <el-table-column label="公司">
          <template slot-scope="scope">
            {{scope.row.cityName}}{{scope.row.companyName}}
          </template>
        </el-table-column>
        <el-table-column prop="deductionAmountFormat" label="扣款金额">
        </el-table-column>
        <el-table-column label="扣款理由备注" width="120">
          <template slot-scope="scope">
            <el-tooltip placement="top">
              <div slot="content">{{scope.row.deductionReason}}</div>
              <div class="deductionBox">{{scope.row.deductionReason}}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="createDateStr" label="创建时间" width="100">
        </el-table-column>
        <el-table-column label="状态" align="center" width="110">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.state === 1" type="danger">未付</el-tag>
            <el-tag v-else-if="scope.row.state === 2">已付</el-tag>
            <el-tag v-else-if="scope.row.state === 5" type="success">已付款-部分</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" :width="200" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.state === 1">
              <el-button size="mini" type="primary" icon="el-icon-edit" @click="editCarinfo(scope.row)">编辑</el-button>
              <el-button size="mini" type="danger" icon="el-icon-delete" @click="removeCar(scope.row.id)">删除</el-button>
            </div>
            <div v-else-if="scope.row.state === 2 || scope.row.state === 5">
              <el-button size="mini" type="success" icon="el-icon-tickets" @click="readCar(scope.row)">查看</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagenum" :page-sizes="[1, 5, 10, 20]" :page-size="size"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </el-card>
    <!-- 添加编辑搜索表单弹窗 -->
    <el-dialog :visible.sync="dialogFormVisible" width="50%" @close="hiddlenDialog" :close-on-click-modal="false">
      <template slot="title">
        <div>
          <span style="font-size: 20px">账单信息</span>
        </div>
      </template>
      <div style="width: 90%;">
        <el-form :model="billform" ref="ruleForm" :rules="rulesBillForm" class="billFrominfo" label-width="100px">
          <el-form-item label="账单资产" prop="carData" :rules="depositRules">
            <el-input :disabled="this.billId == '' ? false : true" placeholder="请输入车牌号或车架号" class="input-with-select" v-model="billform.carData" clearable @input="carValueChange">
              <el-select :disabled="this.billId == '' ? false : true" v-model="billModel" slot="prepend" placeholder="请选择" style="width: 100px" @change="carTypeChange">
                <el-option label="车牌号" :value="1"></el-option>
                <el-option label="车架号" :value="2"></el-option>
              </el-select>
            </el-input>
          </el-form-item>
          <!-- <el-form-item label="车牌号" prop="carNumber">
              <el-input :disabled="this.billId == '' ? false : true" placeholder="请输入车牌号" class="carNumber" v-model="billform.carNumber" autocomplete="off"></el-input>
            </el-form-item> -->
          <el-form-item label="扣款金额" prop="deductionAmount">
            <div class="amountBox">
              <div class="amountInput">
                <el-input :disabled="firstAmountChecked" placeholder="请输入扣款金额" v-model="billform.deductionAmount" autocomplete="off"></el-input>
              </div>
              <div class="getAmount">
                <el-checkbox :disabled="firstAmountDis" v-model="firstAmountChecked" @change="firstAmountChange">选择还款说明书的首期费用合计（剔除租赁管理费）</el-checkbox>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="扣款理由" prop="deductionReason">
            <el-input placeholder="该理由客户还款时可见" class="note" type="textarea" v-model="billform.deductionReason" :autosize="{ minRows: 3, maxRows: 5}"></el-input>
          </el-form-item>
          <el-form-item label="" prop="settle">
            <el-checkbox v-model="billform.settle" @change="settleBillChange">结清账单</el-checkbox>
          </el-form-item>
          <el-form-item>
            <el-col :span="11">
              <el-form-item v-if="billform.settle" label="" prop="carState" :rules="[
                { required: billform.settle, message: '请选择车辆状态', trigger: 'change'  }
              ]">
                <el-select v-model="billform.carState" placeholder="车辆状态">
                  <el-option size="mini" :key="item.value" v-for="item in carOptions" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item v-if="billform.settle" label="" prop="contractState" :rules="[
                { required: billform.settle, message: '请选择合同状态', trigger: 'change'  }
              ]">
                <el-select v-model="billform.contractState" placeholder="合同状态">
                  <el-option size="mini" :key="item.value" v-for="item in leaseOptions" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button v-no-more-click type="primary" @click="SaveBillData">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 二次确认弹窗信息 -->
    <!-- 获取账单信息 -->
    <el-dialog class="customBillDialog" width="40%" title="" :visible.sync="innerVisible" append-to-body @close="hiddlenDebitDialog">
      <template slot="title">
        <div style="font-size: 20px">
          <i style="color: #E6A23C; margin: 5px;" class="el-icon-warning"></i>
          <span>请再次确认扣款信息</span>
        </div>
      </template>
      <el-descriptions :colon="false" :labelStyle="{width: '80px'}" style="font-size: 16px;" :column="1" size="medium">
        <el-descriptions-item v-if="billModel == 1" label="车牌号" :contentStyle="{'color': 'black'}">
          {{ enterBillForm.carNumber }}
        </el-descriptions-item>
        <el-descriptions-item v-else-if="billModel == 2" label="车架号" :contentStyle="{'color': 'black'}">
          {{ enterBillForm.carframeNo }}
        </el-descriptions-item>
        <el-descriptions-item label="客户姓名" :contentStyle="{'color': 'black'}">
          {{ enterBillForm.customer }}
        </el-descriptions-item>
        <el-descriptions-item label="扣款金额" :contentStyle="{'color': 'black'}">
          {{ enterBillForm.deductionAmount }}
          <span v-if="firstAmountChecked">（首期费用合计）</span>
        </el-descriptions-item>
        <el-descriptions-item label="扣款理由" :contentStyle="{'color': 'black'}">
          {{ enterBillForm.deductionReason }}
        </el-descriptions-item>
        <template v-if="enterBillForm.settle == 1">
          <el-descriptions-item label="结清账单" :contentStyle="{'color': 'black'}">
            {{ enterBillForm.settle == 1  ? '是' : '' }}
          </el-descriptions-item>
          <el-descriptions-item label="车辆状态" :contentStyle="{'color': 'black'}">
            {{ carOptions.find(item => item.value == enterBillForm.carState).label }}
          </el-descriptions-item>
          <el-descriptions-item label="合同状态" :contentStyle="{'color': 'black'}">
            {{ leaseOptions.find(item => item.value == enterBillForm.contractState).label }}
          </el-descriptions-item>
        </template>
      </el-descriptions>
      <div slot="footer" class="dialog-footer">
        <el-button @click="innerVisible = false">取 消</el-button>
        <el-button v-no-more-click type="primary" @click="enterBillFun">我已确认无误</el-button>
      </div>
    </el-dialog>

    <!-- 查看账单信息 -->
    <el-dialog class="customBillDialog" width="40%" title="账单信息" :visible.sync="PreviewVisible" append-to-body @close="PerviewhiddlenDialog">
      <el-descriptions :colon="false" :labelStyle="{width: '100px'}" style="font-size: 16px;" :column="1" size="medium">
        <el-descriptions-item label="订单号" :contentStyle="{'color': 'black'}">
          {{ billPreviewData.id }}
        </el-descriptions-item>
         <template v-if="billPreviewData.enablePart === null">
          <el-descriptions-item label="支付时间" :contentStyle="{ color: 'black' }">
            {{ billPreviewData.paymentDate }}
          </el-descriptions-item>
          <el-descriptions-item label="支付方式" :contentStyle="{ color: 'black' }">
            {{ billPreviewData.paymentMethod }}
          </el-descriptions-item>
          <el-descriptions-item label="交易单号" :contentStyle="{ color: 'black' }">
            {{ billPreviewData.transactionNumber }}
          </el-descriptions-item>
        </template>
        <el-descriptions-item label="结清账单" :contentStyle="{'color': 'black'}">
          {{ billPreviewData.settle == 1  ? '是' : '否' }}
        </el-descriptions-item>
        <template v-if="billPreviewData.settle == 1">
          <el-descriptions-item label="车辆状态" :contentStyle="{'color': 'black'}">
            {{ carOptions.find(item => item.value == billPreviewData.carState).label }}
          </el-descriptions-item>
          <el-descriptions-item label="合同状态" :contentStyle="{'color': 'black'}">
            {{ leaseOptions.find(item => item.value == billPreviewData.contractState).label }}
          </el-descriptions-item>
        </template>
        <el-descriptions-item :labelStyle="{width: '0px'}">
          <div style="width: 100%; height: 1px; background-color: #dcdfe6; position: relative; left: -10px;"></div>
        </el-descriptions-item>
        <el-descriptions-item label="操作员" :contentStyle="{'color': 'black'}">
          {{ billPreviewData.createUserName }}
        </el-descriptions-item>
        <el-descriptions-item label="创建时间" :contentStyle="{'color': 'black'}">
          {{ billPreviewData.createDate }}
        </el-descriptions-item>
        <el-descriptions-item v-if="billPreviewData.enablePart === 1" :labelStyle="{width: '0px'}">
          <div style="width: 100%; height: 1px; background-color: #dcdfe6; position: relative; left: -10px;"></div>
        </el-descriptions-item>
      </el-descriptions>
      <template v-if="billPaidList.length > 0">
        <div class="billPaidListTitle">已还款记录</div>
        <el-steps direction="vertical" :space="100">
          <el-step icon="el-icon-success" status="process" v-for="item in billPaidList" :key="item.id" :title="'还款金额：' + item.amount">
            <template slot="description">
              <div>
                <div>交易单号：{{ item.transactionNumber }}</div>
                <div>支付方式：{{ item.paymentMethod }}</div>
                <div>支付时间：{{ item.paymentDate }}</div>
              </div>
            </template>
          </el-step>
        </el-steps>
      </template>
    </el-dialog>
  </div>
</template>
    
<script>
import { cityList, companyList } from "@/api/index.js";

import { getCustomizedBillList, addCustomizedBill, getInfoByCarNumber, updateCustomizedBill, deleteCustomizedBill, getInfoByCarFrameNo } from "@/api/customizedbill.js";
import { getBillPaidList } from "@/api/billRecord.js";

export default {
  name: "",
  data() {
    return {
      queryFrom: {
        id: "", // 订单号
        carNumber: "", // 车牌号
        cityId: "", // 所在城市
        companyId: "", // 所属公司
        state: "", // 付款状态
      },
      optionsCity: [], //城市列表
      optionsCompany: [], // 公司列表
      optionsStatus: [
        { value: 1, label: "未付" },
        { value: 2, label: "已付" },
        { value: 5, label: "已付款-部分" }
      ],

      tableData: [], //表格数据
      pagenum: 1, // 页码
      size: 10, // 单页数据数
      total: 0, //数据总量

      dialogFormVisible: false, // 添加编辑弹窗
      billform: {
        carNumber: "",
        deductionAmount: "",
        deductionReason: "",
        carframeNo: "",
        carData: "", // 车牌号/车架号
        settle: false, // 传参 0 1
        carState: "", 
        contractState: "",
      },
      carOptions: [
        { value: 2, label: "已处置" },
        { value: 4, label: "已买断" },
        { value: 8, label: "合同结束" },
      ],
      leaseOptions: [
        { value: 3, label: "已结束" },
        { value: 4, label: "已结束(36)" },
      ],
      billModel: 1,
      firstAmountChecked: false, // 首期金额
      rulesBillForm: {
        // carNumber: [
        //   { required: true, message: "请输入车牌号", trigger: "blur" },
        //   {
        //     message: '车牌号格式错误',
        //     pattern:
        //       /^[\u4e00-\u9fa5]{1}[A-Z]{1}[A-Z_0-9]{5,6}$/,
        //     trigger: 'blur'
        //   }
        // ],
        deductionAmount: [
          { required: true, message: "请输入扣款金额", trigger: "change" },
          {
            message: "请输入0.01~999999.99之间的数值",
            pattern: /^(?!0(\.0{1,2})?$|100000(\.0{1,2})?$)\d{1,6}(\.\d{1,2})?$/,
            trigger: "blur",
          },
        ],
        deductionReason: [
          { required: true, message: "请输入扣款理由", trigger: "blur" },
          {
            min: 0,
            max: 100,
            message: "扣款理由长度在0到100个字符",
            trigger: "blur",
          },
        ],
      },

      billId: "", //当前账单Id

      innerVisible: false, // 确认信息
      enterBillForm: {}, //相关信息
      PreviewVisible: false, //查看弹窗
      billPreviewData: {}, // 预览数据

      firstAmountDis: true, // 首期金额禁用
      billPaidList: [], // 已付款账单列表
    };
  },
  watch: {
    // 监听城市选项是否为空
    "queryFrom.cityId"(newVal) {
      if (newVal == "") {
        this.optionsCompany = [];
        this.queryFrom.companyId = "";
      } else {
        // 获取公司列表
        this.getcompanyList();
        this.queryFrom.companyId = "";
      }
    },
    // 同步实际车牌号或车架号
    "billform.carData"(newVal) {
      if (this.billModel == 1) {
        this.billform.carNumber = this.billform.carData;
        this.billform.carframeNo = "";
      } else if (this.billModel == 2) {
        this.billform.carframeNo = this.billform.carData;
        this.billform.carNumber = "";
      }
    },
  },
  created() {
    // 初始化表格数据
    this.getCustomBillList();
    this.getcityList();
  },
  computed: {
    depositRules() {
      const rules = [];
      if (this.billModel == 1) {
        rules.push({ required: true, message: "车牌号不能为空", trigger: "blur" });
        rules.push({ message: "车牌号格式错误", pattern: /^[\u4e00-\u9fa5]{1}[A-Z]{1}[A-Z_0-9]{5,6}$/, trigger: "blur" });
      }
      if (this.billModel == 2) {
        rules.push({ required: true, message: "车架号不能为空", trigger: "blur" });
        rules.push({ message: "车架号格式错误(标准17位)", pattern: /^[a-zA-Z0-9]{17}$/, trigger: "blur" });
      }

      return rules;
    },
  },
  methods: {
    // 获取城市列表
    getcityList() {
      cityList().then((res) => {
        this.optionsCity = res.data.dataList;
        window.sessionStorage.setItem("cityList", JSON.stringify(this.optionsCity));
      });
    },
    // 获取城市列表~
    getcompanyList() {
      companyList(this.queryFrom.cityId).then((res) => {
        console.log(res);
        this.optionsCompany = res.data.dataList;
      });
    },
    searchList() {
      this.pagenum = 1;
      this.size = 10;
      this.getCustomBillList();
    },
    getCustomBillList() {
      getCustomizedBillList(this.queryFrom, this.pagenum, this.size).then((res) => {
        console.log(res);
        const dataList = [];
        res.data.dataList.forEach((element) => {
          element.deductionAmountFormat = Number(element.deductionAmount).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
          element.createDateStr = this.$moment(element.createDate).format("YYYY-MM-DD HH:mm:ss");
          dataList.push(element);
        });
        this.tableData = dataList;
        this.total = res.data.total;
      });
    },

    // 监听pagesize改变的事件
    handleSizeChange(newSize) {
      this.size = newSize;
      this.pagenum = 1;
      this.getCustomBillList(); // 发起数据请求
    },
    // 监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.pagenum = newPage;
      this.getCustomBillList(); // 发起数据请求
    },
    addBill() {
      this.dialogFormVisible = true;
      // 阻止校验信息初始化就展示~
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate(["deductionAmount"]);
      });
    },
    SaveBillData() {
      if (this.billId == "") {
        this.$refs.ruleForm.validate((valid) => {
          if (valid && this.billModel == 1) { // 车牌号模式
            const { carNumber, deductionAmount, deductionReason, settle, carState, contractState } = this.billform;
            getInfoByCarNumber({ carNumber, deductionAmount, deductionReason }).then((res) => {
              console.log(res);
              let params = {
                carNumber,
                deductionAmount,
                deductionReason,
                fixedAssetsId: res.data.car.assetsId,
                cityId: res.data.car.cityId,
                companyId: res.data.car.companyId,
                customer: res.data.contract.customer,
              };

              const settleObj = {
                settle: settle ? 1 : 0,
                carState,
                contractState,
              };
              if (settle) params = { ...params, ...settleObj };
              this.enterBillForm = params;
              this.innerVisible = true;
            });
          } else if (valid && this.billModel == 2) { // 车架号模式
            const { carframeNo, deductionAmount, deductionReason, settle, carState, contractState } = this.billform;
            getInfoByCarFrameNo({ carframeNo, deductionAmount, deductionReason }).then((res) => {
              console.log(res);
              let params = {
                carframeNo,
                deductionAmount,
                deductionReason,
                fixedAssetsId: res.data.car.assetsId,
                cityId: res.data.car.cityId,
                companyId: res.data.car.companyId,
                customer: res.data.contract.customer,
              };
              const settleObj = {
                settle: settle ? 1 : 0,
                carState,
                contractState,
              };
              if (settle) params = { ...params, ...settleObj };
              this.enterBillForm = params;
              this.innerVisible = true;
            });
          }
        });
      } else {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            const { deductionAmount, deductionReason, settle, carState, contractState } = this.billform;
            const settleObj = {
                settle: settle ? 1 : 0,
                carState,
                contractState,
              };
            const params = settle ?  { ...settleObj } : { settle: 0 };
            updateCustomizedBill({ deductionAmount, deductionReason, ...params }, this.billId).then((res) => {
              this.dialogFormVisible = false;
              this.getCustomBillList();
              this.$message.success("账单编辑成功");
            });
          }
        });
      }
    },
    enterBillFun() {  
      addCustomizedBill(this.enterBillForm).then((res) => {
        this.innerVisible = false;
        this.dialogFormVisible = false;
        this.pagenum = 1;
        this.size = 10;
        this.getCustomBillList();
        this.$message.success("账单新增成功");
      });
    },
    hiddlenDialog() {
      console.log("重置");
      this.$refs.ruleForm.resetFields(); /// /添加表单验证--重置数据
      Object.keys(this.billform).forEach((key) => (this.billform[key] = ""));
      this.billId = "";
      this.billModel = 1;
      this.firstAmountChecked = false;
      this.firstAmountDis = true;
    },
    hiddlenDebitDialog() {
      console.log("取消提交");
      this.enterBillForm = {};
    },
    editCarinfo(billInf) {
      console.log(billInf);
      const { id, carNumber, carframeNo, deductionAmount, deductionReason, settle, carState, contractState} = billInf;
      this.billId = id;
      if (carNumber == "" || carNumber == null) {
        this.billModel = 2;
        this.billform.carData = carframeNo;
      } else {
        this.billModel = 1;
        this.billform.carData = carNumber;
      }
      this.billform.settle = settle == 1 ? true : false;
      this.billform.carState = carState;
      this.billform.contractState = contractState;
      this.billform.deductionAmount = deductionAmount;
      this.billform.deductionReason = deductionReason;
      this.dialogFormVisible = true;
      this.firstAmountDis = false;
    },
    removeCar(id) {
      this.$confirm("此操作将删除该定制账单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteCustomizedBill(id).then((res) => {
            console.log(res);
            this.pagenum = 1;
            this.size = 10;
            this.getCustomBillList();
            this.$message.success("已成功删除");
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    readCar(billInf) {
      billInf.createDate = this.$moment(billInf.createDate).format("YYYY-MM-DD HH:mm:ss");
      billInf.paymentDate = billInf.paymentDate ? this.$moment(billInf.paymentDate).format("YYYY-MM-DD HH:mm:ss") : "";
      this.billPreviewData = billInf;
      if (billInf.enablePart === null) {
        this.PreviewVisible = true;
        return false;
      }
      getBillPaidList(billInf.id).then((res) => {
        const billPaidList = res.data.map(res => {
          res.amount = res.amount.toString();
          res.paymentDate = res.paymentDate ? this.$moment(res.paymentDate).format("YYYY-MM-DD HH:mm:ss") : "";   
          return res;
        });  
        this.billPaidList = billPaidList;
        this.PreviewVisible = true;      
      })

    },
    PerviewhiddlenDialog() {
      this.billPreviewData = {};
      this.PreviewVisible = false;
      this.billPaidList = [];
    },
    carTypeChange(e) {
      this.billform.carData = "";
      this.$refs.ruleForm.clearValidate(["carData"]); // 移除目标资产校验
      this.firstAmountDis = true;
    },
    firstAmountChange(e) {
      if (e) {
        if (this.billform.carData === "") {
          this.$message.warning("请先输入车牌号或车架号");
          return;
        }
        const { carNumber, carframeNo, deductionAmount, deductionReason } = this.billform;
        if (this.billModel == "1") {
          getInfoByCarNumber({ carNumber, deductionAmount, deductionReason, calcFirstAmount: 1 }).then((res) => {
            console.log(res);
            this.billform.deductionAmount = res.data.firstAmount;
          });
        } else if (this.billModel == "2") {
          getInfoByCarFrameNo({ carframeNo, deductionAmount, deductionReason, calcFirstAmount: 1 }).then((res) => {
            console.log(res);
            this.billform.deductionAmount = res.data.firstAmount;
          });
        }
        console.log("发起请求赋值!");
      } else {
        this.billform.deductionAmount = "";
      }
    },
    // 首期费用合计获取必须符合条件
    carValueChange(e) {
      console.log(e);
      if (this.billModel == "1") {
        let params = e;
        if (params === "") {
          this.firstAmountDis = true;
          return;
        } else {
          const regex = /^[\u4e00-\u9fa5]{1}[A-Z]{1}[A-Z_0-9]{5,6}$/;
          const match = regex.test(params);
          if (!match) {
            this.firstAmountDis = true;
            return;
          } else {
            this.firstAmountDis = false;
            this.firstAmountChecked = false;
            // this.billform.deductionAmount = "";
            return;
          }
        }
      } else if (this.billModel == "2") {
        let params = e;
        if (params === "") {
          this.firstAmountDis = true;
          return;
        } else {
          const regex = /^[a-zA-Z0-9]{17}$/;
          const match = regex.test(params);
          if (!match) {
            this.firstAmountDis = true;
            return;
          } else {
            this.firstAmountDis = false;
            this.firstAmountChecked = false;
            // this.billform.deductionAmount = "";
            return;
          }
        }
      }
    },

    // 重置 状态form-item值
    settleBillChange(value) {
      this.billform.carState = "";
      this.billform.contractState = "";
    },
  },
};
</script>
    
<style lang='less' scoped>
#customizedBill {
  padding: 60px 20px 20px 20px;

  .deductionBox {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .amountBox {
    display: flex;
    align-items: center;
    height: 40px;
    box-sizing: border-box;

    .amountInput {
      width: 180px;
    }

    .getAmount {
      flex-grow: 1;
      margin-left: 20px;
      line-height: 20px;
      color: #cccfd6;
      button {
        padding: 0px;
      }

      .el-checkbox {
        display: flex;
        align-items: center;

        /deep/ .el-checkbox__inner {
          top: 1px;
        }
      }

      /deep/ .el-checkbox__label {
        white-space: normal;
        flex-grow: 1;
      }
    }
  }
}
</style>